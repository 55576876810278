import React, {Component} from 'react';
import {Link,Redirect} from 'react-router-dom';
import { ListGroup, ListGroupItem, Button } from 'reactstrap';
import client from '../Client';

class Users extends Component {
	constructor(props) {
		super(props);

		this.state = {
			users: null,
			loading: false,
		};

		this.loadUsers = this.loadUsers.bind(this);
	}

	componentDidMount() {
		this._ismounted = true;

		this.loadUsers();
	}

	async loadUsers(){

		this.setState({ loading: true });
		const users = await(client.getUsers().then(
			response => {
				return response.data
			},
			error => { }
		));

		if(this._ismounted){
			this.setState({
				users,
				loading: false
			});
		}
	}


	render() {

		if(client.getProfile().role !== 'admin'){
			return <Redirect to="/" />;
		}

		const {users,loading} = this.state;
		return (
		  <div className="container">
			<div className="clearfix mb-3">
				<Button color="success" tag={Link} to="/user/add" className="float-right">Add User</Button>
			</div>

			<ListGroup>
			  {users === null && loading && <ListGroupItem>Loading Users...</ListGroupItem>}
			  {
				users && users.map(user => (
				  <ListGroupItem key={user.id}>
					
						<div className="row">
							<div className="col">Name: {user.name}</div>
							<div className="col">Email: {user.email}</div>
							<div className="col">Username: {user.username}</div>
							<div className="col">Role: {user.role===null?'user':user.role}</div>
							<div className="col"><Link to={`/user/${user.id}`} className="btn btn-success">Edit</Link></div>
						</div>
					
				  </ListGroupItem>
				))
			  }
			</ListGroup>
		  </div>
		)
	}

	componentWillUnmount() {
		this._ismounted = false;
	}
}

export default Users;