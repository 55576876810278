import React, { Component } from 'react';
import {Route,withRouter,Switch} from 'react-router-dom';
import { Alert } from 'reactstrap';

import client from './Client';
import PrivateRoute from './components/PrivateRoute';

import NavBar from './components/NavBar';
import Restaurants from './components/Restaurants';
import Restaurant from './components/Restaurant';
import RestaurantForm from './components/RestaurantForm';

import Users from './components/Users';
import UserForm from './components/UserForm';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';




class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: '',
		};

		this.handleError = this.handleError.bind(this);
	}


	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.onRouteChanged();
		}
	}

	onRouteChanged() {
		//clear error message
		this.handleError(null);
		//console.log("ROUTE CHANGED");
	}


	render() {
		return (
		  <div>
			<NavBar/>
			<div className="container">
				{this.state.error && <Alert color="info" isOpen={this.state.error.length>0} toggle={this.onErrorDismiss} fade={false}>{this.state.error}</Alert>}
				<Switch>
					<PrivateRoute exact path='/' component={Restaurants}/>
					<PrivateRoute exact path='/restaurant/update/:restaurantId(\d+)' component={RestaurantForm}/>
					<PrivateRoute exact path='/restaurant/add' component={RestaurantForm}/>
					<PrivateRoute exact path='/restaurant/:restaurantId(\d+)' component={Restaurant}/>

					<PrivateRoute exact path='/user' component={Users}/>
					<PrivateRoute exact path='/user/add' component={UserForm}/>
					<PrivateRoute exact path='/user/:userId(\d+)' component={UserForm}/>

					<Route exact path='/login' component={LoginPage}/>
					<Route exact path='/register' component={RegisterPage}/>
				</Switch>
			</div>
		  </div>
		);
	}
	componentDidMount() {
		client.subscribeError(this.handleError);
	}

	handleError(message){
		this.setState({ error: message });
	}

	onErrorDismiss = () => {
		this.setState({ error: null });
	}

	componentWillUnmount() {
		client.subscribeError(null);
	}


	componentWillReceiveProps(nextProps) {
		const locationChanged = nextProps.location !== this.props.location;
		if(locationChanged){
			this.handleError(null);
		}
	}
}

export default withRouter(App);