import React from 'react';
import {Link, withRouter, NavLink as RRNavLink} from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import client from '../Client';

class NavBar extends React.Component {

	constructor(props) {
		super(props);

		this.toggleNavbar = this.toggleNavbar.bind(this);
		this.state = {
		  collapsed: true
		};
	}

	toggleNavbar() {
		this.setState({
			collapsed: !this.state.collapsed
		});
	}

	signOut = () => {
		client.signOut();
		this.props.history.replace('/');
	}

	render() {
		return (
			<Navbar color="faded" dark className="bg-primary" fixed="top" expand="md">
				<NavbarBrand tag={Link} className="mr-auto" to="/">Restaurant Review App</NavbarBrand>
				<NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
				<Collapse isOpen={!this.state.collapsed} navbar>
					<Nav className="mr-auto" navbar>
						{
							client.isAuthenticated() && client.getProfile().role === 'admin' &&
							<NavItem>
								<NavLink tag={RRNavLink} exact to="/user" activeClassName="active">Manage Users</NavLink>
							</NavItem>
						}
					</Nav>
					<Nav className="ml-auto" navbar>
						<NavItem>
						{
							!client.isAuthenticated() &&
							<Link className="btn btn-dark" to="/login">Sign In</Link>
						}
						{
							client.isAuthenticated() &&
							<div>
							  <Link className="mr-3 text-white" to={`/user/${client.getProfile().id}`}>{client.getProfile().name}</Link>
							  <button className="btn btn-dark" onClick={() => {this.signOut()}}>Sign Out</button>
							</div>
						}
						</NavItem>
					</Nav>
				</Collapse>
			</Navbar>
		);
	}
}

export default withRouter(NavBar);

/*
		<nav className="navbar navbar-dark bg-primary fixed-top">
		  <Link className="navbar-brand" to="/">
			Restaurant Review App
		  </Link>
		  {
			!client.isAuthenticated() &&
			<Link className="btn btn-dark" to="/login">Sign In</Link>
		  }
		  {
			client.isAuthenticated() &&
			<div>
			  <label className="mr-2 text-white">{client.getProfile().name}</label>
			  <button className="btn btn-dark" onClick={() => {signOut()}}>Sign Out</button>
			</div>
		  }
		</nav>
*/