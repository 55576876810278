import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { ListGroup, ListGroupItem, Button } from 'reactstrap';
import ReplyForm from './ReplyForm';

import client from '../Client';
import Range from 'rc-slider/lib/Range';
import 'rc-slider/assets/index.css';

class Restaurants extends Component {
	constructor(props) {
		super(props);

		this.state = {
			restaurants: null,
			pendingReviews: null,
			loading: false,
			loadingReviews: false,
			sliderValues: [0, 5],
		};

		this.loadRestaurants = this.loadRestaurants.bind(this);
		this.loadPendingReviews = this.loadPendingReviews.bind(this);
	}

	componentDidMount() {
		this._ismounted = true;

		this.loadRestaurants();
		if(client.getProfile().role === 'owner') this.loadPendingReviews();
	}

	async loadRestaurants(){

		this.setState({ loading: true });
		const restaurants = await(client.getRestaurants(this.state.sliderValues[0],this.state.sliderValues[1]).then(
			response => {
				return response.data
			},
			error => {}
		));

		if(this._ismounted){
			this.setState({
				restaurants,
				loading: false
			});
		}
	}

	async loadPendingReviews(){
		
		this.setState({ loadingReviews: true });
		const pendingReviews = await(client.getPendingReviews().then(
			response => {
				return response.data
			}
		));

		if(this._ismounted){
			this.setState({
				pendingReviews,
				loadingReviews: false
			});
		}
	}

	handleChange = sliderValues => {
		
		if(this.state.loading) return;

		this.setState({ sliderValues },this.loadRestaurants);
	};

	render() {
		const {restaurants,pendingReviews,loading,loadingReviews} = this.state;
		return (
		  <div className="container">
			{client.getProfile().role !== null && <Button color="success" tag={Link} to="/restaurant/add" className="float-right">Add Restaurant</Button>}
			
			
			<div style={{margin:'20px 0 30px', maxWidth:'400px'}}>
				<p>Filter by rating:</p>
				<Range min={0} max={5} marks={{1:1, 2:2, 3:3, 4:4, 5:5}} disabled={loading} step={0.1} defaultValue={this.state.sliderValues} onAfterChange={this.handleChange}/>
			</div>

			<ListGroup className="my-4">
			  {restaurants === null && loading && <ListGroupItem>Loading Restaurants...</ListGroupItem>}
			  {
				restaurants && restaurants.map(restaurant => (
				  <ListGroupItem key={restaurant.id}>
					<Link to={`/restaurant/${restaurant.id}`}><h4>{restaurant.title}</h4></Link>
					<p className="mb-0">Address: {restaurant.address}<br/>Rating: {restaurant.rating}<br/>Review count: {restaurant.reviews}</p>
				  </ListGroupItem>
				))
			  }
			</ListGroup>

			{client.getProfile().role === 'owner' && 
				<div>
				{!pendingReviews && loadingReviews &&<p>Loading Pending Reviews</p>}
				{pendingReviews &&
					<div>
					<h3>Pending Reviews:</h3>
					<ListGroup>
					{pendingReviews.map(review => (
						<ListGroupItem key={review.id}>
							<div>User <b>{review.user.name}</b> at <b>{review.visited}</b> gave rating <b>{review.rating}</b> and left comment:</div>
							<div className="p-2 my-3 bg-light">{review.comment}</div>
							<ReplyForm reviewId={review.id} onReplySubmit={this.loadPendingReviews} />
						</ListGroupItem>
					))
					}
					</ListGroup>
					</div>
				}
				</div>
			}
		  </div>
		)
	}

	componentWillUnmount() {
		this._ismounted = false;
	}
}

export default Restaurants;