import React, {Component} from 'react';
import client from '../Client';
import {Link} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { ListGroup, ListGroupItem, Button } from 'reactstrap';
import ReviewForm from './ReviewForm';
import ReviewAdminForm from './ReviewAdminForm';


class Restaurant extends Component {
	constructor(props) {
		super(props);
		this.state = {
			restaurant: null,
			reviews: null,
			loading: false,
			loadingReviews: false,
			error: '',
			pageCurrent : 1,
			pageCount : 1,
		};

		this.loadReviews = this.loadReviews.bind(this);
	}

	componentDidMount() {
		this._ismounted = true;
		const { match: { params } } = this.props;

		this.setState({ loading: true, loadingReviews: true });


		client.getRestaurant(params.restaurantId).then(
			response => {
				if(this._ismounted) this.setState({ restaurant:response.data, loading:false });
			},
			error => {
				console.log(error);
				console.log(error.response);
				if(this._ismounted) this.setState({ error: error.name + ': ' + error.message, loading:false })
			}
		);

		this.loadReviews(params.restaurantId,1);
	}

	loadReviews(restaurantId,page){
		client.getReviews(restaurantId,page).then(
			response => {
				if(this._ismounted){
					var pageCurrent = parseInt(response.headers['x-pagination-current-page']) || 1;
					var pageCount = parseInt(response.headers['x-pagination-page-count']) || 1;

					this.setState({ reviews:response.data, loadingReviews:false, pageCurrent, pageCount });
				}
			},
			error => {if(this._ismounted) this.setState({ loadingReviews:false })}
		);
	}

	handlePageClick = data => {
		// make it 1-based as reuired in backend
		let selected = parseInt(data.selected)+1;

		this.setState({ pageCurrent: selected }, () => {
			this.loadReviews(this.state.restaurant.id,this.state.pageCurrent);
		});
	};


	handleDelete = () => {
		if(!this.state.restaurant) return;

		this.setState({ loading: true});
		client.deleteRestaurant(this.state.restaurant.id).then(
			response => {
				if(this._ismounted){
					const { from } = this.props.location.state || { from: { pathname: "/" } };
					this.props.history.push(from);

					this.setState({ loading: false});
				}
			},
			error => {if(this._ismounted) this.setState({ loading: false}); }
		);
	};


	render() {
		const {restaurant,reviews,loading,loadingReviews} = this.state;
		if (restaurant === null && loading) return <p>Loading restaurant information ...</p>;
		if (restaurant === null && !loading) return <p>No data ... </p>;

		return (
			<div className="row">
			  <div className="col-12">
				<h1 className="display-3">{restaurant.title}</h1>
				{client.getProfile().role === 'admin' && <Button color="danger" onClick={this.handleDelete} className="float-right" >Delete</Button>}
				{client.getProfile().role === 'admin' && <Button color="success" tag={Link} to={`/restaurant/update/${restaurant.id}`} className="float-right mr-2">Update</Button>}
				<p className="lead">Address: {restaurant.address}</p>
				<p>
					Review count: {restaurant.reviews}<br/>
					Average rating: {restaurant.rating}<br/>
					Highest rated review: {restaurant.ratingMax}<br/>
					Lowest rated review: {restaurant.ratingMin}<br/>
					Restaurant Owner: {restaurant.user.name}
				</p>

				<h2>Reviews:</h2>
				
				{reviews === null && loadingReviews && <p>Loading reviews...</p>}
				{reviews &&
					<div className="mb-3">
						<ListGroup>
					{
						reviews.map((review, idx) => (
						  <ListGroupItem key={review.id}>

							{client.getProfile().role === 'admin' && <ReviewAdminForm review={review} onReviewSubmit={()=>{this.loadReviews(this.state.restaurant.id,1)}} />}


							<div>
								<span className="mr-3"><b>User:</b> {review.user.name}</span>
								<span className="mr-3"><b>Rating:</b> {review.rating}</span>
								<span className="mr-3"><b>Visited:</b> {review.visited}</span>
							</div>
							<div className="my-3"><b>Comment:</b><br/> {review.comment}</div>

							{review.reply &&
								<div><b>Reply:</b><div className="p-3 border-left bg-light">{review.reply}</div></div>
							}
							
						  </ListGroupItem>
						))
					}
						</ListGroup>
					{ this.state.pageCount > 1 &&
						<ReactPaginate
							  previousLabel={'previous'}
							  nextLabel={'next'}
							  breakLabel={'...'}
							  forcePage={this.state.pageCurrent-1}
							  breakClassName={'break-me'}
							  pageClassName = {'page-item'}
							  pageLinkClassName = {'page-link'}
							  activeClassName = {'active'}
							  pageCount={this.state.pageCount}
							  initialPage={this.state.pageCurrent-1}
							  disableInitialCallback = {true}
							  marginPagesDisplayed={2}
							  pageRangeDisplayed={5}
							  onPageChange={this.handlePageClick}
							  containerClassName={'pagination my-3'}
							  subContainerClassName={'pages pagination'}
							  previousClassName = {'page-item'}
							  nextClassName = {'page-item'}
							  previousLinkClassName = {'page-link'}
							  nextLinkClassName = {'page-link'}

						/>
					}
					</div>
				}
				{restaurant.canReview &&
					<ReviewForm restaurantId={this.state.restaurant.id} onReviewSubmit={()=>{this.loadReviews(this.state.restaurant.id,1)}} />
				}
			  </div>
			</div>
		)
	}

	componentWillUnmount() {
		this._ismounted = false;
	}
}

export default Restaurant;
