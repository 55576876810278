import React from 'react';
import client from '../Client';
import { Alert } from 'reactstrap';

class RestaurantForm extends React.Component {
    constructor(props) {
        super(props);

        //client.logout();

        this.state = {
            title: '',
            address: '',
            loading: false,
			formErrors: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        const { title, address } = this.state;

        // stop here if form is invalid
        if (!title) {
			console.log('restaurant form validation failed');
            return;
        }

        this.setState({ loading: true });
        client.addRestaurant(title,address,this.props.match.params.restaurantId).then(
			response => {
				if(!this._ismounted) return;
				this.setState({ loading: false });
				this.props.history.push({ pathname: "/" });
			},
			error => { 
				if(!this._ismounted) return;

				let formErrors =  null;
				if(error.response.status === 422){
					formErrors = error.response.data;
				}
				this.setState({ formErrors, loading: false }); 
			}
		);
    }

    render() {
        const { title, address, loading, formErrors  } = this.state;


        return (
            <div className="row">
			<div className="col-md-6">
				<h2>{this.props.match.params.restaurantId ? 'Update' : 'Add'} Restaurant:</h2>
				<form name="form" onSubmit={this.handleSubmit}>
					<div className={'form-group'}>
						<label htmlFor="title">Title</label>
						<input type="text" className="form-control" name="title" onChange={this.handleChange} value={title} />
					</div>
					<div className={'form-group'}>
						<label htmlFor="address">Address</label>
						<input type="text" className="form-control" name="address" onChange={this.handleChange} value={address} />
					</div>
					<div className="form-group">
						<button className="btn btn-primary" disabled={loading}>Submit</button>
						{loading &&
							<img alt="..." src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
						}
					</div>
					{formErrors &&
						<Alert color="light">
							{formErrors.map((error, i) => (
								<p key={error.field}>{error.message}</p>	  
							))}
						</Alert>
                    }
				</form>
			</div>
			</div>
        );
    }

	componentDidMount() {
		this._ismounted = true;
		
		if(this.props.match.params.restaurantId){
			this.setState({ loading:true });
			client.getRestaurant(this.props.match.params.restaurantId).then(
				response => {
					if(this._ismounted){
						const {title, address} = response.data;
						this.setState({ title, address, loading:false });
					}
				},
				error => {
					if(this._ismounted) this.setState({ loading:false })
				}
			);
		}
	}

	componentWillUnmount() {
		this._ismounted = false;
	}
}

export default RestaurantForm;

