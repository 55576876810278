import React from 'react';
import { Redirect } from 'react-router-dom';
import client from '../Client';
import { Alert, Button } from 'reactstrap';

class RegisterPage extends React.Component {
    constructor(props) {
        super(props);

        //client.logout();

        this.state = {
			id: null,
            username: '',
            password: '',
			role: '',
			name: '',
			email: '',
            submitted: false,
            loading: false,
			loadingRole: false,
            formErrors: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
		this.handleRoleSubmit = this.handleRoleSubmit.bind(this);
    }

	componentDidMount() {
		this._ismounted = true;

		if(this.props.match.params.userId){
			this.setState({ loading:true });
			client.getUser(this.props.match.params.userId).then(
				response => {
					if(this._ismounted){
						const {id,username,name,email} = response.data;
						let role = response.data.role;
						if(!role) role = '';
						this.setState({ id,username,name,email,role, loading:false });
					}
				},
				error => {
					if(this._ismounted) this.setState({ loading:false })
				}
			);
		}
	}


    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { id,username, password, name, email } = this.state;

        // stop here if form is invalid
        if (!(username && name && email)) {
            return;
        }

        this.setState({ loading: true });
        client.addUser(username,password,name,email,id).then(
			resp => {
				if(!this._ismounted) return;
				this.setState({ loading: false });
				this.props.history.push({ pathname: "/user" });
			},
			error => { 
				if(!this._ismounted) return;
				let formErrors =  null;
				if(error.response.status === 422){
					formErrors = error.response.data;
				}
				this.setState({ formErrors, loading: false }); 
			}
		);
    }

	handleRoleSubmit(e) {
        e.preventDefault();

        const { id,role } = this.state;

        this.setState({ loadingRole: true });

        client.setUserRole(id,role).then(
			resp => {
				if(!this._ismounted) return;
				this.setState({ loadingRole: false });
				this.props.history.push({ pathname: "/user" });
			},
			error => { 
				if(!this._ismounted) return;
				this.setState({ loadingRole: false }); 
			}
		);
    }

	handleDelete = () => {
		if(!this.state.id) return;

		this.setState({ loading: true});
		client.deleteUser(this.state.id).then(
			response => {
				if(this._ismounted){
					this.setState({ loading: false});
					const { from } = this.props.location.state || { from: { pathname: "/" } };
					this.props.history.push(from);
				}
			},
			error => {if(this._ismounted) this.setState({ loading: false}); }
		);
	};

    render() {
        const { id,username, password,name,email, submitted, loading, loadingRole, formErrors } = this.state;

		// only admin can edit other accounts
		if(client.getProfile().role !== 'admin' && (!this.props.match.params.userId || client.getProfile().id !== parseInt(this.props.match.params.userId))){
			return <Redirect to="/" />;
		}

        return (
            <div className="col-md-6 col-md-offset-3">
				{client.getProfile().role === 'admin' && <Button color="danger" onClick={this.handleDelete} className="float-right">Delete User</Button>}
                <h2>{this.props.match.params.userId ? 'Update' : 'Add new'} account</h2>
				
                <form name="form" onSubmit={this.handleSubmit}>
                    
					<div className={'form-group' + (submitted && !name ? ' has-error' : '')}>
                        <label htmlFor="name">Name</label>
                        <input type="text" className="form-control" name="name" value={name} onChange={this.handleChange} />
                        {submitted && !name &&
                            <div className="help-block">Name is required</div>
                        }
                    </div>
					<div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                        <label htmlFor="email">Email</label>
                        <input type="email" className="form-control" name="email" value={email} onChange={this.handleChange} />
                        {submitted && !email &&
                            <div className="help-block">Email is required</div>
                        }
                    </div>
					<div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                        <label htmlFor="username">Username</label>
                        <input type="text" className="form-control" name="username" value={username} onChange={this.handleChange} />
                        {submitted && !username &&
                            <div className="help-block">Username is required</div>
                        }
                    </div>
                    <div className={'form-group' + (submitted && !password && !id ? ' has-error' : '')}>
                        <label htmlFor="password">New Password</label>
                        <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                        {submitted && !password && !id &&
                            <div className="help-block">Password is required</div>
                        }
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary mr-2" disabled={loading}>Save</button>
                        {loading &&
                            <img alt="..." src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        }
                    </div>
                    {formErrors &&
						<Alert color="light">
							{formErrors.map((error, i) => (
								<p key={error.field}>{error.message}</p>	  
							))}
						</Alert>
                    }
                </form>

				{client.getProfile().role === 'admin' &&
				<form name="roleForm" className="mt-5" onSubmit={this.handleRoleSubmit}>
					<div className="form-group">
                        <label>Role</label>
							<select value={this.state.role} name="role" onChange={this.handleChange} className="form-control">
								<option value="">User</option>
								<option value="owner">Owner</option>
								<option value="admin">Admin</option>
							</select>
						
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary mr-2" disabled={loadingRole}>Save</button>
                        {loadingRole &&
                            <img alt="..." src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        }
                    </div>
                </form>
				}
            </div>
        );
    }

	componentWillUnmount() {
		this._ismounted = false;
	}
}

export default RegisterPage;

