import React from 'react';
import client from '../Client';
import Rating from 'react-rating';
import { Alert, Button, Modal, ModalBody } from 'reactstrap';

class ReviewAdminForm extends React.Component {
    constructor(props) {
        super(props);

        //client.logout();

        this.state = {
			id : null,
            comment: '',
			reply: '',
			is_pending:1,
            rating: null,
            submitted: false,
            loading: false,
			formErrors: null,
			formIsVisible: true,
			modal: false
        };

        this.handleChange = this.handleChange.bind(this);
		this.handleRatingChange = this.handleRatingChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
		this.toggle = this.toggle.bind(this);
    }

	toggle() {
		this.setState(prevState => ({
			modal: !prevState.modal
		}));
		
		// reset values on modal toggle
		const {id,comment, rating, reply, visited, is_pending} = this.props.review;
		this.setState({ id,comment, rating, reply, visited, is_pending }); 
	}

    handleChange(e) {
		const target = e.target;
		const value = target.type === 'checkbox' ? (target.checked?1:0) : target.value;
		const name = target.name;

		this.setState({
		  [name]: value
		});
    }

	handleRatingChange(val) {
        this.setState({ rating: val });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { id, comment, rating, reply,visited, is_pending } = this.state;

        // stop here if form is invalid
        if (!id || !rating) {
            return;
        }

        this.setState({ loading: true });
        client.editReview(id,rating,comment,reply,visited, is_pending).then(
			response => {
				if(!this._ismounted) return;
				this.setState({ loading: false, formIsVisible:false });
				
				//this.toggle();
				this.props.onReviewSubmit();
			},
			error => { 
				if(!this._ismounted) return;
				let formErrors =  null;
				// check http status - data validation failure 
				if(error.response.status === 422){
					formErrors = error.response.data;
				}
				this.setState({ formErrors, loading: false }); 
			}
		);
    }


    render() {
        const { rating, comment, reply,visited, is_pending, submitted, loading, formErrors  } = this.state;
		
		if(!this.state.formIsVisible) return null;

        return (
			<div>
			<Button color="success" onClick={this.toggle} className="btn-sm float-right mr-2">Update</Button>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
			<ModalBody>
				<h2>Update review:</h2>
				<form name="form" onSubmit={this.handleSubmit}>
					<div className={'form-group' + (submitted && !rating ? ' has-error' : '')}>
						<label>Rating</label>
						<div>
							<Rating onChange={this.handleRatingChange} initialRating={this.state.rating} />
						</div>
					</div>
					<div className={'form-group' + (submitted && !visited ? ' has-error' : '')}>
                        <label htmlFor="visited">Visited</label>
                        <input type="text" className="form-control" name="visited" value={visited} onChange={this.handleChange} />
                        {submitted && !visited &&
                            <div className="help-block">Visited is required</div>
                        }
                    </div>

					<div className='form-group'>
                        <label htmlFor="is_pending">Is pending reply</label>
                        <input type="checkbox" className="form-control" checked={is_pending>0} name="is_pending" value={is_pending} onChange={this.handleChange} />
                    </div>

					<div className={'form-group'}>
						<label htmlFor="comment">Comment</label>
						<textarea className="form-control" name="comment" onChange={this.handleChange} value={comment} />
					</div>
					<div className={'form-group'}>
						<label htmlFor="comment">Reply</label>
						<textarea className="form-control" name="reply" onChange={this.handleChange} value={reply} />
					</div>
					<div className="form-group">
						<button className="btn btn-primary" disabled={loading}>Submit</button>
						{loading &&
							<img alt="..." src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
						}
					</div>
					{formErrors &&
						<Alert color="light">
							{formErrors.map((error, i) => (
								<p key={error.field}>{error.message}</p>	  
							))}
						</Alert>
                    }
				</form>
			</ModalBody>
			</Modal>
			</div>
        );
    }

	componentDidMount() {
		this._ismounted = true;

		const {id,comment, rating, reply, visited, is_pending} = this.props.review;
		this.setState({ id,comment, rating, reply, visited, is_pending }); 
	}

	componentWillUnmount() {
		this._ismounted = false;
	}
}

export default ReviewAdminForm;

