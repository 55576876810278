import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import client from '../Client';
import { Alert } from 'reactstrap';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        //client.logout();

        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            formErrors: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { username, password } = this.state;

        // stop here if form is invalid
        if (!(username && password)) {
            return;
        }

        this.setState({ loading: true });
        client.signIn(username,password).then(
			resp => {
				const { from } = this.props.location.state || { from: { pathname: "/" } };
				this.props.history.push(from);
			},
			error => { 
				if(!this._ismounted) return;
				let formErrors =  null;
				// check http status - data validation failure 
				if(error.response.status === 422){
					formErrors = error.response.data;
				}
				this.setState({ formErrors, loading: false }); 
			}
		);
    }

    render() {
        const { username, password, submitted, loading, formErrors } = this.state;

		if(client.isAuthenticated()){
			return <Redirect to="/" />;
		}

        return (
			<div className="row">
				<div className="col-md-6">
                <div className="alert alert-info row">
					<div className="col">
						Username: admin<br />
						Password: admin123
					</div>
					<div className="col">
						Username: owner<br />
						Password: owner123
					</div>
					<div className="col">
						Username: user<br />
						Password: user123
					</div>
                    
                </div>
                <h2>Login</h2>
                <form name="form" onSubmit={this.handleSubmit}>
                    <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                        <label htmlFor="username">Username</label>
                        <input type="text" className="form-control" name="username" value={username} onChange={this.handleChange} />
                        {submitted && !username &&
                            <div className="help-block">Username is required</div>
                        }
                    </div>
                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                        <label htmlFor="password">Password</label>
                        <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                        {submitted && !password &&
                            <div className="help-block">Password is required</div>
                        }
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary mr-2" disabled={loading}>Login</button>
                        {loading &&
                            <img alt="..." src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        }
						<Link to="/register" className="ml-2">Register</Link>
                    </div>
                    {formErrors &&
						<Alert color="light">
							{formErrors.map((error, i) => (
								<p key={error.field}>{error.message}</p>	  
							))}
						</Alert>
                    }
                </form>
				</div>
			</div>
        );
    }


	componentDidMount() {
		this._ismounted = true;
	}

	componentWillUnmount() {
		this._ismounted = false;
	}
}

export default LoginPage;

