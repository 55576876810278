import axios from 'axios';


class Client {
	constructor() {

		axios.defaults.baseURL = 'https://api.toptal.brainwork.lv/';

		this.handleError = null;
			
		this.isAuthenticated = this.isAuthenticated.bind(this);
		this.signIn = this.signIn.bind(this);
		this.signOut = this.signOut.bind(this);
		this.getProfile = this.getProfile.bind(this);
		this.setAuthHeader = this.setAuthHeader.bind(this);
		this._error = this._error.bind(this);
		this.subscribeError = this.subscribeError.bind(this);


		let user = JSON.parse(localStorage.getItem('user'));

		if (user && user.access_token) {
			this.profile = user;
			this.idToken = user.access_token;
			this.setAuthHeader();
		}
	}

	getProfile() {
		return this.profile;
	}


	isAuthenticated() {
		return this.idToken;
	}

	subscribeError(fun){
		this.handleError = fun;
	}

	
	_error(error){
		if(typeof this.handleError  === "function"){

			if (typeof error.response === 'undefined') {
				this.handleError('A network error occurred. This could be a CORS issue or a dropped internet connection. It is not possible for us to know.');
			}

			// ignore form validation error in global error message
			if(error.response && error.response.status !== 422){
				let msg = error.name + ': ' + error.message;
				//console.log(error.response);
				if(error.response && error.response.data.message){
					msg = msg + " : " + error.response.data.message;
				}
				this.handleError(msg);
			}
		}
		return Promise.reject(error); 
	}

	signIn(username,password) {

		return axios.post('/users/login', {
			username,
			password
		}).then((response) => {
			let data = response.data;
			if(data.access_token){
				this.idToken = data.access_token;
				this.profile = data;

				localStorage.setItem('user', JSON.stringify(data));

				this.setAuthHeader();
			}
			//console.log(data);

			return response;
		}).catch(this._error);
	}

	signOut() {
		localStorage.removeItem('user');

		this.idToken = null;
	}

	register(username,password,name,email){
		return this.addUser(username, password,name,email).then((response) => {
			let data = response.data;
			if(data.access_token){
				this.idToken = data.access_token;
				this.profile = data;
				localStorage.setItem('user', JSON.stringify(data));
				this.setAuthHeader();
			}
			return response;
		});
	}

	setAuthHeader(){
		axios.defaults.headers.common = {'Authorization': `Bearer ${this.idToken}`};
	}


	getRestaurants(rating_min, rating_max,page=1){
		return axios.get('/restaurants',{params:{rating_min,rating_max,page,sort:'-rating'}}).catch(this._error);
	}

	getRestaurant(id) {
		return axios.get('/restaurants/'+id+'?expand=user').catch(this._error);
	}

	deleteRestaurant(id) {
		return axios.delete('/restaurants/'+id).catch(this._error);
	}

	getReviews(id, page=1) {
		return axios.get('/reviews/list?expand=user&sort=-visited&id_restaurant='+id+'&page='+page).catch(this._error);
	}

	getPendingReviews(page=1) {
		return axios.get('/reviews/list-pending?expand=user,restaurant&sort=-visited&id_user='+this.profile.id+'&page='+page).catch(this._error);
	}

	addReview(restaurantId,rating,comment){
		return axios.post('/reviews', {
			id_user:this.profile.id,
			id_restaurant:restaurantId,
			rating,
			comment
		}).catch(this._error);
	}

	editReview(id,rating,comment,reply,visited, is_pending){
		return axios.put('/reviews/'+id, {
			rating,
			comment,
			reply,
			visited,
			is_pending
		}).catch(this._error);
	}

	addReply(reviewId,reply){
		return axios.put('/reviews/'+reviewId, {
				reply,
				is_pending:0,
		}).catch(this._error);
	}

	addRestaurant(title,address,restaurantId=null){
		if(restaurantId){
			return axios.put('/restaurants/'+restaurantId, {
				id_user:this.profile.id,
				title,
				address
			}).catch(this._error);
		}
		
		return axios.post('/restaurants', {
			id_user:this.profile.id,
			title,
			address
		}).catch(this._error);
	}

	getUsers(page=1) {
		return axios.get('/users?page='+page).catch(this._error);
	}

	getUser(id) {
		return axios.get('/users/'+id).catch(this._error);
	}

	setUserRole(id,role) {
		return axios.get('/users/set-role?id_user='+id+'&role_name='+role).catch(this._error);
	}

	addUser(username, password,name,email,userId=null){
		if(userId){ // edit user
			return axios.put('/users/'+userId, {
				username,
				password_field:password,
				name,
				email
			}).catch(this._error);
		}
		
		return axios.post('/users', {
			username,
			password_field:password,
			name,
			email
		}).catch(this._error);
	}

	deleteUser(id) {
		return axios.delete('/users/'+id).catch(this._error);
	}
}

const client = new Client();

export default client;