import React from 'react';
import client from '../Client';
import Rating from 'react-rating';
import { Alert } from 'reactstrap';

class ReviewForm extends React.Component {
    constructor(props) {
        super(props);

        //client.logout();

        this.state = {
            comment: '',
            rating: null,
            submitted: false,
            loading: false,
			formErrors: null,
			formIsVisible: true
        };

        this.handleChange = this.handleChange.bind(this);
		this.handleRatingChange = this.handleRatingChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

	handleRatingChange(val) {
        this.setState({ rating: val });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { comment, rating } = this.state;

        // stop here if form is invalid
        if (!this.props.restaurantId || !rating) {
            return;
        }

        this.setState({ loading: true });
        client.addReview(this.props.restaurantId,rating,comment).then(
			response => {
				if(!this._ismounted) return;
				this.setState({ loading: false, formIsVisible:false });

				this.props.onReviewSubmit();
			},
			error => { 
				if(!this._ismounted) return;
				let formErrors =  null;
				// check http status - data validation failure 
				if(error.response.status === 422){
					formErrors = error.response.data;
				}
				this.setState({ formErrors, loading: false }); 
			}
		);
    }

    render() {
        const { rating, comment, submitted, loading, formErrors  } = this.state;
		
		if(!this.state.formIsVisible) return null;

        return (
            <div>
				<h2>Add review:</h2>
				<form name="form" onSubmit={this.handleSubmit}>
					<div className={'form-group' + (submitted && !rating ? ' has-error' : '')}>
						<label>Rating</label>
						<div>
							<Rating onChange={this.handleRatingChange} initialRating={this.state.rating} />
						</div>
					</div>
					<div className={'form-group'}>
						<label htmlFor="comment">Comment</label>
						<textarea className="form-control" name="comment" onChange={this.handleChange} value={comment} />
					</div>
					<div className="form-group">
						<button className="btn btn-primary" disabled={loading}>Submit</button>
						{loading &&
							<img alt="..." src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
						}
					</div>
					{formErrors &&
						<Alert color="light">
							{formErrors.map((error, i) => (
								<p key={error.field}>{error.message}</p>	  
							))}
						</Alert>
                    }
				</form>
			</div>
        );
    }

	componentDidMount() {
		this._ismounted = true;
	}

	componentWillUnmount() {
		this._ismounted = false;
	}
}

export default ReviewForm;

